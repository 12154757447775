<template>
    <div class="postBox">
        <Search />
        <div class="communityContent">
            <div class="nav">
                <div @click="goUstand">
                    <div class="img">
                        <img src="../../assets/images/community/logo.png" alt="" />
                    </div>
                    <p>了解企胖胖</p>
                </div>
                <div v-for="item in nav.slice(0,4)" :key="item.id" @click="navClickFun(item)">
                    <div class="img">
                        <img style="width:50px;height:50px" :src="$util.host +item.thumbnail" alt="">
                    </div>
                    <p>{{ item .name}}</p>
                </div>
                <div v-if="nav.length > 4">
                    <el-dropdown placement="bottom" trigger="click" @command="indexChange">
                        <img style="margin:17px 0 4px ;width:50px;height:50px;display:block" src="../../assets/images/serve/gd.png" alt="">
                        <span class="el-dropdown-link">
                            更多
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item :command="item" v-for="item in nav.slice(4,100)" :key="item.id">{{item.name}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
                <!-- <div class="noneBox" v-if="showClass">
                    <p v-for="item in 3">
                        类别{{item}}
                    </p>
                    <p>我的创作中心</p>
                </div> -->
            </div>
        </div>
        <div class="postContent">
            <div class="postC">
                <p class="title">我要发帖</p>
                <div>
                    <span>选择板块：</span>
                    <el-select v-model="form.classifyId" placeholder="请选择">
                        <el-option v-for="item in nav" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div>
                    <span>封面(支持jpg、png格式图片)：</span>
                    <el-upload :limit="1" :action="action" list-type="picture-card" :on-success="beforeLoad" :on-remove="removeImg">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%" :src="$util.host +dialogImageUrl" alt="" />
                    </el-dialog>
                </div>
                <div>
                    <span>标题: </span>
                    <input type="text" v-model="form.name" />
                </div>
                <div>
                    <span>内容：</span>
                </div>
                <div>
                    <!-- <mavon-editor @save="saveDoc" @change="updateDoc" ref="editor" v-model="doc">
                    </mavon-editor> -->
                    <!-- <vue-editor v-model="form.content" id='editor' style="height:400px;" :editorToolbar="customToolbar" useCustomImageHandler @image-added="handleImageAdded"></vue-editor> -->
                    <vue-editor v-model="form.content" id="editor" :readOnly="true" useCustomImageHandler @image-added="handleImageAdded" />
                </div>
                <div>
                    <el-radio v-model="form.source" :label="1">原创</el-radio>
                    <el-radio v-model="form.source" :label="2">转载</el-radio>
                    <font class="btn" @click="send">发表</font>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>
<script>
import Search from "../../components/forumSearch.vue";
import Footer from "../../components/footer.vue";
import axios from "axios";
import { VueEditor } from "vue2-editor";
export default {
    components: { Search, Footer, VueEditor },
    name: "QppIndex",
    data() {
        return {
            radio: 1,
            dialogImageUrl: "",
            dialogVisible: false,
            disabled: false,
            activeName: "默认",
            active: 0,
            currentPage: 5,
            doc: "",
            showClass: false,
            tab: ["默认", "排行", "精品"],
            nav: [],
            form: {
                name: '',
                content: '',
                cover: '',
                classifyId: '',
                source: 1
            },
            action: this.$util.upload,
            value: "",
            customToolbar: [
                ["bold", "italic", "size", "font", "underline", "color", "italic", "strike", "clean",],
                [{ align: ['', 'center', 'right', 'justify'] }],
                [{
                    header: [false, 1, 2, 3, 4, 5, 6]
                }],
                [{
                    list: "ordered"
                }, {
                    list: "bullet"
                }],
                [{
                    indent: "-1"
                }, {
                    indent: "+1"
                }],
                ["image"],
            ],

        };
    },
    mounted() {
        this.getClass()
    },
    methods: {
        getClass() {
            this.$util.post('/shop/allClassify').then(res => {
                console.log(res);
                this.nav = res.data
            })
        },
        indexChange(item) {
            this.classifyId = item.id
            this.getTopping()
            this.getyFeatured()
            this.getListing()
            this.getDefult()
            this.getBalnner()
        },
        // 上传
        beforeLoad(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.form.cover = imgs.join(',')
        },
        removeImg(file, fileList) {
            console.log(fileList);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.form.cover = imgs.join(',')
        },
        send() {
            console.log(this.form);

            this.$util.post('/forum/release', this.form).then(res => {
                console.log(res);
                if (res.code == 200) {
                    this.$router.push({ path: '/postSuccess' })
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        updateDoc(markdown, html) {
            // 此时会自动将 markdown 和 html 传递到这个方法中
            console.log("markdown内容: " + markdown);
            console.log("html内容:" + markdown);
        },
        saveDoc(markdown, html) {
            // 此时会自动将 markdown 和 html 传递到这个方法中
            console.log("markdown内容:" + markdown);
            console.log("html内容:" + html);
        },
        handleRemove(file) {
            console.log(file);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleDownload(file) {
            console.log(file);
        },
        navClickFun(item) {
            this.$router.push({ path: '/community' })
        },
        goUstand() {
            this.$router.push({ path: '/understand' })
        },
        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        //查看详情
        goDetail() {
            this.$router.push({ path: "communityDetail" });
        },
        // 富文本上传图片
        handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();

            formData.append('file', file);
            console.log(new FormData());
            axios({
                url: this.$util.host + "/file/upload",
                method: "POST",
                data: formData
            })
                .then(result => {
                    let url = this.$util.host + result.data.data.url; // Get url from response
                    console.log(url);
                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });


        },
        changeTab(id) {
            this.active = id;
            console.log(this.active);
        },
    },
};
</script>
<style>
.activeClass {
    color: #e40012 !important;
    border-bottom: 2px solid #e40012;
}
</style>
<style lang="scss" scoped>
.postBox {
    .postContent {
        width: 100%;
        background: #f6f6f6;
        padding-top: 25px;
        .btn {
            width: 180px;
            line-height: 50px;
            text-align: center;
            color: #ffffff;
            font-size: 18px;
            cursor: pointer;
            border-radius: 4px;
            display: block;
            background: #e40012;
            height: 50px;
        }
        .postC {
            width: 1140px;
            padding: 30px;
            background: #ffffff;
            margin: auto;
            .title {
                border-bottom: 1px solid #eeeeee;
                padding-bottom: 20px;
                color: #222222;
                font-size: 24px;
            }
            div:nth-child(7) {
                display: flex;
                justify-content: right;
                align-items: center;
            }
            div:nth-child(4) {
                display: flex;
                justify-content: left;
                align-items: center;
                input {
                    width: 900px;
                    border: 1px solid #dbdbdb;
                    height: 50px;
                }
            }
            div {
                margin-top: 30px;
                span {
                    color: #999999;
                }
            }
            div:nth-child(3) {
                margin-top: 0;
                align-items: center;
                span {
                    margin-right: 35px;
                }
                display: flex;
                justify-content: left;
            }
        }
    }
    .noneBox {
        position: absolute;
        width: 98px;
        border: 1px solid #d1d1d1;
        right: -30px;
        top: 130px;
        background: #ffffff;
        z-index: 999 !important;
        padding: 20px 0;
        p {
            font-size: 14px;
            padding-bottom: 15px;
            cursor: pointer;
        }
        p:last-child {
            padding-bottom: 0;
        }
    }
    .tabContentBox {
        width: 100%;
        height: 1700px;
        background: #f6f6f6;
        .tabContent {
            width: 1200px;
            margin: auto;
            padding-top: 30px;
            .pageInput {
                display: flex;
                justify-content: center;
                padding-top: 38px;
            }
            .list {
                padding: 30px;
                height: 198px;
                background: #ffffff;
                cursor: pointer;
                margin-bottom: 20px;
                display: flex;
                justify-content: space-between;
                p:nth-child(1) {
                    color: #222222;
                    font-size: 22px;
                    padding-bottom: 12px;
                }
                p:nth-child(2) {
                    display: flex;
                    justify-content: flex-start;
                    font-size: 14px;
                    padding-bottom: 10px;
                    span {
                        color: #666666;
                        padding-right: 74px;
                    }
                    font {
                        color: #222222;
                    }
                }
                p:nth-child(3) {
                    font-size: 16px;
                    height: 84px;
                    margin-bottom: 40px;
                }
                p:nth-child(4) {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 14px;
                    span {
                        color: #666666;
                        padding-right: 34px;
                    }
                    font {
                        color: #222222;
                    }
                }
                .img {
                    width: 198px;
                    height: 198px;
                    margin-right: 20px;
                }
            }
        }
    }
    .communityContent {
        width: 1200px;
        margin: auto;
        .tableBox {
            margin-top: 50px;
            .tableList {
                display: flex;
                justify-content: flex-start;
                font-size: 22px;
                div {
                    margin-right: 63px;
                    cursor: pointer;
                    padding-bottom: 5px;
                    text-align: center;
                    width: 80px;
                    color: #666666;
                }
            }
        }
        .recommendBox {
            width: 1200px;
            height: 428px;
            .recommend {
                position: absolute;
                width: 100%;
                left: 0;
                height: 418px;
                background: #fff5f6;

                .fourList {
                    width: 1200px;
                    margin: auto;
                    display: flex;
                    justify-content: space-between;
                    .list {
                        display: flex;
                        justify-content: flex-start;
                        div {
                            cursor: pointer;
                            padding: 10px 0;
                            p:nth-child(1) {
                                width: 128px;
                                height: 42px;
                                margin-bottom: 35px;
                                font-size: 16px;
                                color: #222222;
                            }
                            p:nth-child(2) {
                                color: #222222;
                                font-size: 16px;
                            }
                        }
                    }
                    img {
                        width: 120px;
                        cursor: pointer;
                        margin-right: 20px;
                        height: 120px;
                    }
                }

                .twoList {
                    padding: 50px 0;
                    width: 1200px;
                    margin: auto;
                    display: flex;
                    justify-content: space-between;
                    .list {
                        display: flex;
                        justify-content: flex-start;
                        div {
                            cursor: pointer;
                            padding: 10px 0;
                            p:nth-child(1) {
                                width: 192px;
                                height: 62px;
                                margin-bottom: 45px;
                                font-size: 24px;
                                color: #222222;
                            }
                            p:nth-child(2) {
                                color: #222222;
                                font-size: 16px;
                            }
                        }
                    }
                    img {
                        width: 330px;
                        cursor: pointer;
                        margin-right: 20px;
                        height: 148px;
                    }
                }
            }
        }
        .bannerBox {
            margin-bottom: 50px;
            .bannerImg {
                width: 100%;
                height: 400px;
            }
        }
        .communityData {
            padding-bottom: 10px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 65px;
            width: 100%;
            align-items: center;
            border-bottom: 1px solid #f1f1f1;
            .title {
                color: #222222;
                font-size: 24px;
            }
            .data {
                display: flex;
                justify-content: right;
                div {
                    font-size: 16px;
                    margin-left: 50px;
                    font {
                        color: #999999;
                    }
                    span {
                        color: #222222;
                    }
                }
            }
        }

        .img {
            width: 100px;
            height: 50px;
            cursor: pointer;
            padding-bottom: 18px;
        }

        .nav {
            padding-bottom: 50px;
            padding-top: 40px;
            display: flex;
            justify-content: space-between;
            text-align: center;
            position: relative;
            p {
                cursor: pointer;
            }
        }
    }
}
</style>
